<template>
    <div class="table">
        <el-table border ref="multipleTable" :data="tableData" :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}" tooltip-effect="dark" style="width: 100%"
            height="60vh"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>

            <el-table-column prop="id" label="序号" width="120">
            </el-table-column>
            <el-table-column prop="name" label="角色名称" >
            </el-table-column>
         
            <el-table-column prop="desc" label="描述">

            </el-table-column>
            <el-table-column label="状态" width="120">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.state" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>
                </template>
            </el-table-column>
         
            <el-table-column fixed="right" label="操作" width="200">
                <template slot-scope="scope">
                    <span class="pointer" @click="setliemit(scope.row)">设置权限</span>
                    <span class="pointer">删除</span>
                    <span class="pointer margin_left_10">编辑</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="addLabelCategoryDialog">
            <el-dialog title="设置功能权限" :visible.sync="dialog" :modal-append-to-body='false'>
                <div class="folder">

                    <el-checkbox-group v-model="stateObj.checkList">
                        <el-checkbox label="复选框 A"></el-checkbox>
                        <el-checkbox label="复选框 B"></el-checkbox>
                        <el-checkbox label="复选框 C"></el-checkbox>

                    </el-checkbox-group>

                </div>
                <span slot="footer" class="dialog-footer">

                    <el-button type="primary" class="saveBtn" @click="submit">保存</el-button>
                    <el-button @click="dialog = false" class="cancel">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
        import { authLists } from "@/api/limitsauthority";
    export default {
        props: ["tableData"],
        name: '',
        data() {
            return {
                stateObj: {
                    id: "",
                    rules: "",
                    menus: "",
                    checkList: []
                },

                dialog: false,
                value: true,
               
            }
        },
        created(){
           
        },
        methods: {
            setliemit(row){
                this.dialog=true
                console.log(this.$store.state.user.user)
                this.getDataList(row)
            },
            getDataList(row) {
      
                authLists({account_id:this.$store.state.user.user.id,id:row.id,mark:"account"}).then((res) => {
                    if (res.code == 200) {
                    //  this.stateObj.checkList=
                    } else {

                        
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.$message.error(error.message);
                });
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            }
        }
    }
</script>

<style scoped>


</style>