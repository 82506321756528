<template>
    <div class="other_page_r">
        <el-card class="card_box">
            <div slot="header" class="card_title">
                <span>权限管理/角色信息</span>
            </div>
            <!-- <div>
                <el-form :inline="true" :model="search" class="demo-form-inline">
                    <el-form-item label="姓名账户">
                        <el-input class="custom_input_170" v-model="searchInfo.truename" placeholder=""></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select class="custom_input_170"  v-model="searchInfo.truename" placeholder="">
                            <el-option label="全部" value="shanghai"></el-option>
                            <el-option label="启用" value="beijing"></el-option>
                            <el-option label="未启用" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                 
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">查询</el-button>
                        <el-button type="primary" @click="onSubmit">重置</el-button>
                    </el-form-item>
                </el-form>
        
            </div> -->

            <div class="table_box">
                <tablec :tableData="tableData"></tablec>
            </div>
            <div class="flex justify_content_sb aligin_items_c table_footer custom_btn">
                <el-button>批量删除</el-button>
                <div>

                    <pagination v-show="total > 0" :total="total" :page.sync="searchInfo.page"
                        :limit.sync="searchInfo.size" @pagination="getDataList" />

                </div>
            </div>
        </el-card>
      
    </div>
</template>

<script>
    import tablec from "./tablec.vue"
    import { getRolesLists } from "@/api/limitsauthority";
    export default {
        components: { tablec },
        name: '',
        data() {
            return {
             
                tableData: [],
                total: Number,
                searchInfo: {
                    page: 1,
                    size: 10,
                    truename: '',
                    uid: '',
                    auth_type: '',
                    check_authentication: ''
                },

            }
        },
        created() {
            this.getDataList()
        },
        methods: {

            getDataList() {
                getRolesLists(this.searchInfo).then((res) => {
                    if (res.code == 200) {
                        this.tableData = res.data.data
                        this.total = res.data.total;
                    } else {

                        this.tableData = []
                        this.total = 0;
                        
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.$message.error(error.message);
                });
            }
        }

    }
</script>

<style scoped>

</style>